import { useContext } from "react";
import PROFILE_DATA from "../data/profile-data";
import { AppContext } from "../utils/context";

export default function AboutUs() {
  return (
    <div>
      <h1>About Us</h1>

      <div className="space-y-10">
        {PROFILE_DATA.map((v, i) => (
          <Profile data={v} key={i} />
        ))}
      </div>
    </div>
  );
}

function Profile({ data }) {

  return (
    <div className="md:flex space-x-5 bg-gray-100 rounded-2xl overflow-hidden p-5 shadow-xl">
      <img
        style={{
          width: 300,
        }}
        src={data.img}
        alt="Profile Photo"
      />

      <div>
        <h2>{data.name}</h2>
        <div>{data.desc}</div>
      </div>
    </div>
  );
}
