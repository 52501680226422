export default function VCM() {

  return <div style={{}} className=" w-full bg-white flex flex-col">
  <h1 className="text-2xl font-bold self-center">Why are LIBs important to us?</h1>

  <br></br>
  <div>
      To easily understand the significance of Lithium Ion Batteries on the environmental scale, we decided to zoom in on the current situation at Rice University. 
      Currently, Rice supplies approximately 4 MW or 20% of its energy from 2 natural gas turbine plants as visualized in the map below. 

  </div>

  <br></br>

  <img className="self-center" style={{width: 714, height:499}}src="https://i.imgur.com/tJUnpgB.png" alt="React Image" /> 
  <br></br>




<div>
  These gas turbine plants however do release excess CO2 emissions. 
  Using a constant of 640-817 pounds of CO2 released per MegaWatt-Hour of energy produced from the average gas turbine plant, 
  we performed the calculations below to 
  determine that by using Natural Gas turbine power generation sources,
   Rice has approximately 12,980 Tons CO2 emitted annually.  

   By replacing the Natural Gas turbines with LIB energy storage, 
   it is possible to avoid 130,000 Tons CO2 emissions over 10 years and 390,000 
   Tons CO2 emissions over 30 years, roughly the expected lifetime of the LIB packs.
</div>


<img className="self-center" style={{width: 800, height:220}}src="https://i.imgur.com/Igw5QO2.png" alt="React Image" /> 

<br></br>
<div>
  However, hypotheticallly, by replacing Rice's gas turbine plants with Lithium-Ion Batteries, we can push Rice into a sustainable direction by eliminating its CO2 emissions.
  Next, we decided to calculate how much Lithium-Ion Batteries would be needed to produce 4MW of energy. Using the constant of 1kg of LIB/300W of energy produced, we determine that we need 13,333.33 kg of LIBs. 
  Now suppose we use Tesla as the Lithium-Ion Battery supplier. Given the data that 1 Tesla Megapack supplies 1.5MW of energy, we round up in our calculations to determine that 3 Tesla Megapacks are sufficient to supply 4 MW at Rice. 

</div>

<img className="self-center" style={{width: 744, height:465}}src="https://i.imgur.com/aCpGZ9E.png" alt="React Image" /> 



  
  
  
  </div>;
  
  
  
  
  
  }
  