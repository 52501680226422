import { StrictMode, useState } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./layout/layout";
import { ROUTES } from "./data/routes";
import "./styles.css";
import { AppContext } from "./utils/context";

// --------------------------

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: ROUTES,
  },
]);

function Root() {
  let [mapMsg, setMapMsg] = useState(0);


  return (
    <AppContext.Provider value={{mapMsg, setMapMsg}}>
      <RouterProvider router={router} />
    </AppContext.Provider>
  );
}

root.render(
  <StrictMode>
    <Root />
  </StrictMode>
);
