const PROFILE_DATA = [
  {
    name: "Dr. Glen C Irvin Jr",
    desc: "Dr. Glen C. Irvin Jr. is currently (Nov 2023) a Research Professor in the Department of Chemical and Biomolecular Engineering at Rice University.  He is Co-PI for the ARPA-E OPEN 2018 program focused on significantly improving single and double walled carbon nanotube product quality and production capacity (reducing product cost structure).Dr. Irvin is focusing on integration of CNTs into LIB devices in support of the growing US supply chain of LIB Manufacturing Plants for Battery Electric Vehicles.  The CNTs are used as conductive additives in many LIB device chemistries.  Additionally, Dr. Irvin is exploring how CNTs can be a part of microgrid development in the US to make the US Grid more resilient during the transformation from fossil fuel based energy sources to primarily variable renewable energy sources by 2050.",
    img: "https://profiles.rice.edu/sites/g/files/bxs3881/files/2020-07/glen_irvin_600.jpg",
  },
  {
    name: "Jiwoo Kim",
    desc: "Jiwoo Kim is a high school senior at Tompkins High School in Katy, Texas. Through coursework in classes such as Chemistry and AP Physics, he grew an interest in the functionality of batteries and power sources and wanted to develop a firmer understanding in the current state of energy sources, hence he embarked on this research project with Professor Irvin. Kim’s strongest academic passion lies in Computer Science and he weaved his passion for coding into the project through web development (this website was created entirely from scratch), In the future, he hopes to study and earn a Bachelor in Science in Computer Science and work as a programmer in the constantly-growing IT industry. Outside of school, Kim can be found practicing as a violist for the Tompkins High School Symphony Orchestra or watching his favorite NBA team, the Houston Rockets, play basketball. ",
    img: "https://i.imgur.com/HwCPkDK.jpg",
  },
];

export default PROFILE_DATA;