import React, { useContext, useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import { AppContext } from "../utils/context";

import LOCATIONS from "../data/location-data2.json";

const MAP_SIZE = { width: "80vw", height: "300px" };

export default function Locations2() {
  const { mapMsg } = useContext(AppContext);
  return (
    <div className="w-full flex flex-col align-center">
      <h1>Upcoming Location Database</h1>

      <br></br>

      <div>
        In order to better understand the state of the Lithium-Ion Battery
        market in the US and our LIB power needs today and in the foreseeable
        future, Professor Irvin and I compiled all of the United States LIB
        manufacturing plants currently existing as of May 2023 and future ones.{" "}
      </div>

      <br></br>

      <div>
        I combined research with my skills in React to create an interactive
        map. Click on a point on the map to see detailed information about the
        plant located there.
      </div>

      <br></br>
      <div className="font-bold">
        Note that due to the high number of points, there are 2 maps. This page is only
        for LIB plants in the US that will be in operation in the near future.{" "}
      </div>

      <br></br>
      <div className="border p-4 bg-gray-50 flex flex-col">
        <div className="text-2xl self-center">
          Existing USA Lithium Ion Battery Factory Database
        </div>
        <div>
          <span className="font-bold mr-2">Location:</span>
          {LOCATIONS[mapMsg]?.["Location"]}
        </div>
        <div>
          <span className="font-bold mr-2">Auto Company:</span>
          {LOCATIONS[mapMsg]?.["Auto Company"]}
        </div>
        <div>
          <span className="font-bold mr-2">Battery Company:</span>
          {LOCATIONS[mapMsg]?.["Battery Company"]}
        </div>
        <div>
          <span className="font-bold mr-2">Annual Capacity in GWH:</span>
          {LOCATIONS[mapMsg]?.["Annual Capacity (GWH)"]}
        </div>
        <div>
          <span className="font-bold mr-2">Amount of Investment:</span>
          {LOCATIONS[mapMsg]?.["Amount of Investment"]}
        </div>
        <div>
          <span className="font-bold mr-2">Type of LIB Battery Chemistry:</span>
          {LOCATIONS[mapMsg]?.["Type of LIB Battery Chemistry"]}
        </div>
      </div>

      <div className="mt-5" style={MAP_SIZE}>
        <MapWrapper />
      </div>
    </div>
  );
}

const MapWrapper = GoogleApiWrapper({
  apiKey: "AIzaSyAjx9gBnticBNMoScznPsCmv616uEy7CY8",
})(TheMap);

function TheMap(props) {
  const { setMapMsg } = useContext(AppContext);

  function displayMarkers() {
    return LOCATIONS.map((store, index) => {
      return (
        <Marker
          key={index}
          id={index}
          position={{
            lat: store.Latitude,
            lng: store.Longitude,
          }}
          onClick={() => {
            setMapMsg(index);
          }}
        />
      );
    });
  }

  return (
    <div>
      <Map
        google={props.google}
        zoom={4}
        style={MAP_SIZE}
        initialCenter={{ lat:39.8097343, lng: -98.5556199 }}
      >
        {displayMarkers()}
      </Map>
    </div>
  );
}
