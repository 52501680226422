import Index from "../routes/_index";
import AboutUs from "../routes/aboutus";
import CNTs from "../routes/cnts";
import Locations from "../routes/locations";
import Locations2 from "../routes/locations2";
import VCM from "../routes/vcm";

const ROUTES = [
  { path: "/", title: "CNTs", element: <Index /> },
  { path: "/cnts", title: "CNTs & LIBs", element: <CNTs /> },
  { path: "/vcm", title: "LIBs", element: <VCM /> },
  { path: "/locations", title: "Locations", element: <Locations /> },
  { path: "/locations2", title: "Upcoming Locations", element: <Locations2 /> },
  { path: "/aboutus", title: "About Us", element: <AboutUs /> },
];

export { ROUTES };
