export default function Index() {
  return (
    <div className="h-screen w-11/12 flex flex-col">

      <h1 className="self-center font-bold text-2xl">What are CNTs?</h1>
      <br></br>
      <div className="self-center">
      Carbon Nanotubes (CNTs) have been in development since 1991 when first identified conclusively by Sumio Ijima. Currently, our understanding of CNTs material properties are strong due to various theoretical calculations completed since the advanced material was first realized.  However, the commercial application development has been significantly delayed due to poor control over the synthesis of CNTs (eg. length, diameter, purity) at prices that are compatible with market requirements. 
      </div>

      <br></br>

      <div className="self-center">
      Despite significant application development having transpired over the last 25 years, CNT process efficiency and material availability at production scale and cost has stopped practical deployment of these unique materials on a large scale. 

      </div>

      <br></br>

      <div>Recently, the CNT market has found its first production scale application as Conductive Additives in Lithium Ion Battery devices.  CNT's potential application for Electric Vehicles and the industry's emphasis of sustainable energy sources is driving demand for CNTs, and specifically MWCNTs initially as MWCNTs displace carbon black materials in LIB devices for Electric Vehicles.</div>

      <br></br>
      <div>Below are some basic properties of CNTs and their structure on a microscopic scale:</div>

      <img className="self-center" style={{width: 2454, height:1222}}src="https://i.imgur.com/4KpuYK6.png" alt="React Image" /> 


<br></br>


      




    </div>
  );
}
