export default function CNTs() {

    return <div style={{}} className=" w-11/12 bg-white flex flex-col">
      <h1 className="self-center font-bold text-2xl">What is the significance of CNTs?</h1>

      <br></br>      
      <h2 className="font-bold text-xl">Significance: Battery Electric Vehicles</h2>
      <div>
      With the continuous expansion of the electric vehicle industry in the US, there comes an increase in the demand for the supplies needed to build it. The majority of EVs are powered by Lithium-Ion Batteries. Among the costs to construct an electric vehicle, the battery has the highest cost, emphasizing the needed for automobile companies to produce them efficiently
      </div>
      <br></br>

      <div>
      Below is a breakdown of the different components comprising a Lithium Ion Battery as it is integrated into a Battery Electric Vehicle (BEV)
         </div>
    <br></br>





      <img style={{width: 1744, height:235}}src="https://i.imgur.com/DPSEzz9.png" alt="React Image" /> 
      
      
      <br></br>

      <div>
    The chart below further compares LIB material of the past, present, and future. CNTs bring to LIBs a longer life cycle, improved charging and higher capacity, further compelling consumers to consider purchasing an electric vehicle. 
    </div>
    <br></br>


      <img style={{width: 1752, height:240}}src="https://i.imgur.com/cP7Lwak.png" alt="React Image" /> 


      <br></br>
      <h2 className="self-center text-lg font-bold">How will the US Supply all of the LIBs Needed for the Electrification of the Transportation System?</h2>
      <div style={{width: 1000, height: 550}}className=" self-center flex flex-row">
        <div style={{width: 300, height: 550}} className="">
          <div>Part of our research was to determine the capacity of LIBs being installed in the US as a result of the increased adoption of EVs driven by consumer preferences and US Policy incentives (BIA, IRA etc.)
          </div>
          <br></br>
          <div>We analyzed two cases for LIB Manufacturing Plant Capacity in the US:  
          </div>
          <div>(1) Existing LIB Plants already located in the US - an interactive Map can be found here
          </div>
          <div>(2) LIB Plants in Construction as of November 2023 - an interactive Map can be found here
          </div>
          <br></br>
          <div>In the Figure here, we calculated the Cumulative Installed LIB capacity through 2030 in the US.  Note, these values are changing with continued announcements for new capacity commitments
          </div>
        </div>

        <img className="self-center"style={{width: 700, height:460}}src="https://i.imgur.com/XldbCbm.png" alt="React Image" /> 

      </div>


      
      <br></br>
      <h2 className="self-center text-lg font-bold">CNTs Required for the Electrification of EVs in the USA through 2030 (assuming CNTs are integrated from 2023)</h2>
      <div style={{width: 1000, height: 450}}className=" self-center flex flex-row">
        <div style={{width: 300, height: 450}} className="flex flex-row">
          <div className="self-center">We have also calculated how much CNTs will be required to supply US based EV LIB plants starting from 2023
          </div>
        </div>

        <img className="self-center"style={{width: 700, height:420}}src="https://i.imgur.com/NKv2VnX.png" alt="React Image" /> 

      </div>



      <h2 className="font-bold text-xl">Significance: Battery Electric Storage Systems for the Electrical Grid</h2>

      <div>In addition to EV systems, LIB devices will become important components in the Global Electrical Grid.  In the last few years, installation of Grid-Scale LIBs has been increasing as shown in the Figure below.  Also, the once dominant NMC LIB chemistry is losing market share to LFP and NCA technologies.
      </div>
      <br></br>

      <img className="self-center"style={{width: 900, height:510}}src="https://i.imgur.com/y2N0dAE.png" alt="React Image" /> 

      <br></br>

      <h2 className="font-bold text-xl">US Grid (Utility) Scale Battery Storage Capacity Installed by technology and chemistry</h2>

      <img className="self-center"style={{width: 900, height:430}}src="https://i.imgur.com/L98v0rr.png" alt="React Image" /> 

      <h2 className="font-bold text-xl">How will CNTs be part of our Clean Energy Future?</h2>

      <div>For Americans to rely on CNTs based LIBs for their power, the demand for CNTs will be great. Using data from the 2023 annual power demand, we evaluated two scenarios where power is supplied to the US grid from Lithium Ion Batteries that are charged by (variable) renewable energy sources:
      </div>
      <div>A: Scenario 1:  10% of the 2023 USA Annual power demand is delivered by batteries that are powered by renewable resources
      </div>
      <div>B: Scenario 2:  25% of the 2023 USA Annual power demand is delivered by batteries that are powered by renewable resources
      </div>

      <img className="self-center"style={{width: 910, height:277}}src="https://i.imgur.com/iQdCImq.png" alt="React Image" /> 

      <h2 className="font-bold text-xl">Global Power Generation Sources Mix - Current and Estimated to 2050 by International Renewable Energy Agency</h2>

      <img className="self-center"style={{width: 913, height:383}}src="https://i.imgur.com/DLmgeEa.png" alt="React Image" /> 

  
  
      
      
      
      </div>;
  
  
  
  
  
  }
  